/*
 * Globals
 */
/* Links */
a,
a:focus,
a:hover {
	color: #fff;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap");

/* Custom default button */
.btn-default {
	color: #fff;
	text-shadow: none;
	/* Prevent inheritence from `body` */
	background-color: transparent;
	border: 2px solid #fff;
	border-radius: 20px;
	padding: 0.5rem 2rem;
}

.btn-default:hover,
.btn-default:focus {
	background-color: rgb(89 133 243);
	color: #d5d9f5 !important;
}

/*
 * Base structure
 */
html,
body {
	height: 100%;
}

body {
	background-size: contain;
	color: #fff;
	text-align: center;
	font-family: "Open Sans", sans-serif;
	box-sizing: border-box;
	overflow: hidden;
}
.mainContainer {
	display: flex;
	flex-direction: column;
}
.mainWrapper {
	position: relative;
}

#sliderDiv {
	flex-grow: 1;
	flex-shrink: 0;
}
.introSvgContainer {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.introSvgContainer svg:nth-child(1) {
	width: 40%;
	margin: 0 auto;
}

.progress {
	padding: 8px;
	height: 1.5rem;
	box-sizing: content-box;
	border: 3px solid black;
	position: absolute;
	overflow: visible;
	left: 50%;
	transform: translateX(-50%);
	width: 40%;
	z-index: 999;
	position: fixed;
	order: 3;
	bottom: 35px;
}
.progress-bar {
	background-color: #ee8e1a;
	align-items: flex-end;
	overflow: visible;
}
.progress-bar::after {
	content: url(./assets/images/btc.png);
	transform: translateX(30%) scale(1.2);
}

#landingPageImg {
	width: 50%;
}
#landingPageImg svg {
	width: 100%;
}
.imageContainer svg {
	height: 100%;
}
.content {
	color: #000;
}
.content p {
	font-size: 1.1rem;
}

#logo {
	position: absolute;
	width: 80px;
	top: 6%;
	left: 4%;
	z-index: 99;
}
.imageContainer {
	height: 65%;
}
.slideContainer img {
	display: block;
	width: 100%;
	max-width: 600px;
}
.slideContainer p {
	margin-top: 10px;
	margin-bottom: 0;
}

/* Carousel Indicators */
.carousel-indicators {
	position: fixed;
	order: 3;
	bottom: 0%;
	align-items: center;
}

.carousel-indicators li:nth-child(1) {
	display: none;
}

.carouselControlsRight img {
	mix-blend-mode: difference;
	stroke: #fff;
	stroke-width: 2px;
}
.carouselControlLeft {
	cursor: pointer;
	z-index: 999;
}
.carouselControlLeft img {
	stroke: #fff;
	stroke-width: 2px;
}
.carousel-indicators li {
	background-color: #000;
	background: url(./assets/images/slider-indicator.png) no-repeat;
	border-radius: 40px;
	height: 10px;
	width: 50px;
	opacity: 1;
}

.carousel-indicators .active {
	background: url("./assets/images/btc.png") no-repeat;
	height: 50px;
	width: 50px;
}
/* Overriding default opacity for carousel controls */
.carousel-control-next,
.carousel-control-prev {
	opacity: 1;
	display: block;
	height: fit-content;
	width: 15%;
	top: 50%;
	transform: translateY(-50%);
}

.carousel-custom-arrow {
	height: 50px;
	width: 100px;
}

.riskWarning {
	position: absolute;
	bottom: 15px;
	width: 70%;
	max-width: 960px;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	font-size: 14px;
	font-weight: bold;
}

@media screen and (max-width: 768px) {
	.carousel-control-next,
	.carousel-control-prev {
		width: 25%;
	}

	.carousel-custom-arrow {
		height: auto;
		width: 50px;
	}
}

.customPrevIcon {
	position: absolute;
	cursor: pointer;
	bottom: 50%;
	transform: translateY(50%);
	z-index: 10;
	width: 15%;
	color: #fff;
	text-align: center;
	transition: opacity 0.15s ease;
}

.outgoingSlide {
	background-color: transparent;
	-webkit-transition: background-color 2s;
	transition: background-color 2s;
}

@media (max-width: 768px) {
	#logo {
		top: 4%;
		left: 8%;
		width: 50px;
		margin: 0;
	}
	.masthead-brand {
		float: left;
	}
	.nav-masthead {
		float: right;
	}

	#landingPageImg {
		width: 90%;
	}
	.introSvgContainer svg:nth-child(1) {
		width: 60%;
		margin: 0 auto;
	}
	.introSvgContainer svg:nth-child(2) {
		width: 40%;
		margin: 2rem auto;
	}
	.slideContainer {
		justify-content: center;
	}
	.content {
		padding: 0 10px;
	}
	.heading {
		font-size: 1.3rem;
	}
	.imageContainer {
		height: 40%;
		margin-bottom: 1rem;
	}
	.slideContainer img {
		margin: 0 auto 0 auto;
	}

	.slideContainer p {
		margin-top: 0px;
	}

	.carouselControlLeft img,
	.carouselControlsRight img {
		width: 80%;
	}
	.carousel-indicators {
		display: none;
	}
	.carousel-control-next {
		margin-right: 0.5rem;
	}
	.carousel-control-prev {
		margin-left: 0.5rem;
	}
	.carousel-control-prev,
	.carousel-control-next {
		margin-top: auto;
		margin-bottom: 20px;
		transform: translateY(0%);
	}
	.carousel-indicators .active {
		width: 90px;
		background-size: contain;
		background-position: center;
	}
	.customPrevIcon {
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 99;
		align-items: center;
		justify-content: center;
		width: 15%;
		color: #fff;
		text-align: center;
		height: fit-content;
		margin-bottom: 20px;
		margin-left: 0.5rem;
		transition: opacity 0.15s ease;
		transform: translateY(0%);
		margin-top: auto;
	}
	.riskWarning {
		position: absolute;
		bottom: 45px;
		width: 80%;
		text-align: center;
		font-size: 12px;
		font-weight: bold;
	}
}
@media (max-height: 530px) {
	h2 {
		font-size: 20px;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
