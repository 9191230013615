* {
	box-sizing: border-box;
	font-family: "Open Sans", sans-serif;
	margin: 0px;
	padding: 0px;
}

body {
	text-align: center;
	width: 100vw;
	background-color: #fff;
}

h1 {
	color: green;
}

.mainContainer {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	font-weight: bold;
	font-size: 2rem;
}

.mainContainer::before {
	content: "";
	background: url(../assets/images/bg-top-first.png) top left no-repeat;
	background-size: contain;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	z-index: 1;
}
.mainContainer::after {
	content: "";
	background: url(../assets/images/bg-bottom-first.png) bottom right no-repeat;
	background-size: contain;
	position: absolute;
	bottom: 0px;
	right: 0px;
	width: 100vw;
	height: 100vh;
	z-index: 1;
}

span {
	font-size: 2em;
}

/* Style the topnav links */
.topnav a {
	float: left;
	display: block;
	color: #f2f2f2;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
}

/* Change color on hover */
.topnav a:hover {
	background-color: #ddd;
	color: black;
}

/* Style the content */
.mainContent {
	background-color: #ddd;
	padding: 0px;
	text-align: center;
	z-index: 2;
}
.logo {
	width: 150px;
	position: absolute;
	left: 5%;
	top: 12%;
	z-index: 2;
}
@media screen and (max-width: 768px) {
	.logo {
		top: 9%;
	}
}
